import {TableModel} from "../TableModel";

export class PartCategoryModel {
  constructor() {
    this.loading = false;
    this.pagedData = new TableModel(
      { pageNumber: 1, pageSize: 100 },
      [{ id: 'name', value: 'desc' }],
      []
     );
  }

}
