import React from "react";
import validate from "validate.js";
import {Field, reduxForm} from "redux-form";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import FormTextField from "../../form/FormTextField";
export const formValidation = values => {
  const constraints = {
    categoryName: {
      presence: { message: 'Pole jest wymagane' },
    },
  };

  const errors = validate(values, constraints, { fullMessages: false }) || {};
  return errors;
};

let CategoryForm = ({ handleSubmit }) => {

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Field
          name="categoryName"
          component={FormTextField}
          label="Nazwa kategori"
          fullWidth
        />
      </div>
    </form>
  );
};

CategoryForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};

CategoryForm = reduxForm({
  form: 'addNewCategoryForm',
  validate: formValidation,
})(CategoryForm);

export default connect()(CategoryForm);

