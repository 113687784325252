import React from "react";
import {connect, useDispatch} from "react-redux";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {submit} from "redux-form";
import PropTypes from "prop-types";
// eslint-disable-next-line import/no-unresolved
import PartForm from "./PartForm";

const AddNewBikePartDialog = ({open, onCancel, onSubmit, submitting,
                               fetchingPartCategoriesAll, categoryList}) => {
  const dispatch = useDispatch();

  return(
    <Dialog open={open} fullWidth={false} onClose={onCancel}>
      <DialogTitle>Dodawanie nowej części roweru</DialogTitle>
      <DialogContent>
        {!fetchingPartCategoriesAll && <PartForm categoryList={categoryList} onSubmit={onSubmit}/>}
        {fetchingPartCategoriesAll && <CircularProgress color="secondary" size={24} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} disabled={submitting}>
          Anuluj
        </Button>
        <Button variant="contained" onClick={() => dispatch(submit('addNewPartForm'))} disabled={submitting || fetchingPartCategoriesAll}
                color="primary">
          Zapisz
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AddNewBikePartDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  categoryList: PropTypes.array.isRequired,
  fetchingPartCategoriesAll: PropTypes.bool.isRequired,
};

function mapDispatchToProps() {
  return {
  };
}

function mapStateToProps() {
  return {

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewBikePartDialog);
