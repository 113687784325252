import React, {useMemo} from "react";
import validate from "validate.js";
import {Field, reduxForm} from "redux-form";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import FormTextField from "../../form/FormTextField";
import FormSelect from "../../form/FormSelect";
export const formValidation = values => {
  const constraints = {
    partCategory: {
      presence: { message: 'Pole jest wymagane' },
    },
    partName: {
      presence: { message: 'Pole jest wymagane' },
    }
  };


  const errors = validate(values, constraints, { fullMessages: false }) || {};
  return errors;
};

let PartForm = ({ handleSubmit, categoryList }) => {

  const categoryListsMemo = useMemo(() => {
    if(!categoryList) return [];
    return categoryList.map(item => ({
      value: item.id,
      name: item.name
    }));
  },[categoryList])

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Field
          name="categoryId"
          component={FormSelect}
          label={"Kategoria"}
          style={{width: 400}}
          values={categoryListsMemo}
        />
      </div>
      <div>
        <Field
          name="partName"
          component={FormTextField}
          label="Nazwa części"
          fullWidth
        />
      </div>
    </form>
);
};

PartForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
    submitting
:
  PropTypes.bool,
    categoryList
:
  PropTypes.array.isRequired,
};

PartForm = reduxForm({
  form: 'addNewPartForm',
 // validate: formValidation,
})(PartForm);

export default connect()(PartForm);

