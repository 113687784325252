import React from 'react';
import { withRouter } from 'react-router';
import AppFrame from './AppFrame';
import { Switch, Route, Redirect } from 'react-router-dom';
import rooveeTheme from '../styles/rooveeTheme';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { I18nextProvider } from 'react-i18next';
import NotificationsHandler from './notifications/NotificationsHandler';
import './AppStyles.scss';
import routePaths from '../routePaths';
import i18n from '../i18n';
import SignInPage from './auth/signIn/SignInPage';
import HomePage from './HomePage';
import VirtualLocksPage from './virtualLocks/VirtualLocksPage';
import BikeTypesPage from './bikeTypes/BikeTypesPage';
import BikePartsPage from './bikeParts/BikePartsPage';
import BikeTypeDetailsPage from './bikeTypes/BikeTypeDetailsPage';
import MobileUserAccountsPage from './mobileUserAccounts/MobileUserAccountsPage';
import MobileUserAccountDetailsPage from './mobileUserAccounts/MobileUserAccountDetailsPage';
import TenantsPage from './tenants/TenantsPage';
import TenantDetailsPage from './tenants/TenantDetailsPage';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import AutomaticReports from "./automaticReports";
import AutomaticReportsHistory from "./automaticReportsHistory";
import ApiAccess from "./apiAccess";
// eslint-disable-next-line import/no-unresolved
import WithdrawalReport from "./withdrawalReport/WithdrawalReport";
import VirtualLocksExternalTesterPage from "./virtualLocks/VirtualLocksExternalTesterPage";
import WarehousePage from "./warehouse/WarehousePage";

if (!String.prototype.startsWith) {
  String.prototype.startsWith = function (searchString, position) {
    position = position || 0;
    return this.indexOf(searchString, position) === position;
  };
}

class App extends React.Component {
  constructor() {
    super();
  }

  renderUnauthorizedContent() {
    return (
      <div className="root">
        <Switch>
          <Route path={routePaths.virtualLocksExternalTester} component={VirtualLocksExternalTesterPage} />
          <Route path={routePaths.signIn} component={SignInPage} />
          <Redirect to={routePaths.signIn} push />
        </Switch>
        <NotificationsHandler />
      </div>
    );
  }

  renderAppContent() {
    return (
      <I18nextProvider i18n={i18n}>
        <AppFrame>
          <Switch>
            <Route exact path={routePaths.home} component={HomePage} />
            <Route exact path={routePaths.virtualLocks} component={VirtualLocksPage} />
            <Route exact path={routePaths.bikeTypes} component={BikeTypesPage} />
            <Route exact path={routePaths.bikeParts} component={BikePartsPage} />
            <Route exact path={routePaths.newBikeTypeDetails} component={BikeTypeDetailsPage} />
            <Route exact path={routePaths.bikeTypeDetails} component={BikeTypeDetailsPage} />
            <Route exact path={routePaths.mobileUserAccounts} component={MobileUserAccountsPage} />
            <Route exact path={routePaths.mobileUserAccountDetails} component={MobileUserAccountDetailsPage} />
            <Route exact path={routePaths.tenants} component={TenantsPage} />
            <Route exact path={routePaths.tenantDetails} component={TenantDetailsPage} />
            <Route exact path={`${routePaths.automaticReport}/:id`} component={AutomaticReports} />
            <Route exact path={`${routePaths.automaticReport}/:id/:reportId`} component={AutomaticReportsHistory} />
            <Route exact path={`${routePaths.apiAccess}/:id`} component={ApiAccess} />
            <Route exact path={routePaths.withdrawalReport} component={WithdrawalReport} />
            <Route exact path={routePaths.warehouse} component={WarehousePage} />
            <Redirect to={routePaths.home} push />
          </Switch>
          <NotificationsHandler />
        </AppFrame>
      </I18nextProvider>
    );
  }

  render() {
    return (
      <MuiThemeProvider theme={createMuiTheme(rooveeTheme)}>
        <AuthenticatedTemplate>
          {this.renderAppContent()}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          {this.renderUnauthorizedContent()}
        </UnauthenticatedTemplate>
      </MuiThemeProvider>
    );
  }
}

export default withRouter(App);
