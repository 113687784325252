import apiBase from './apiBase';

class BikePartsApi {
  static getAll() {
    return apiBase.create().get('/bike-parts');
  }

  static create(name) {
    return apiBase.create().post('/bike-parts', { name });
  }

  static delete(id) {
    return apiBase.create().delete(`/bike-parts/${id}`);
  }

  static createCategory(categoryName) {
    return apiBase.create().post('/warehouse/category/new', { categoryName });
  }

  static createPart(partName, categoryId) {
    return apiBase.create().post('/warehouse/part/new', { partName, categoryId });
  }

  static getWarehouseReport() {
    return apiBase.create().get('/warehouse/report',{}, { responseType: 'blob', timeout: 120000 });
  }

  static getReport(dateFrom, dateTo) {
    return apiBase.create().get('/bike-parts/report', { dateFrom, dateTo }, { responseType: 'blob', timeout: 120000 });
  }

  static getCategoryList(pageNumber, pageSize, sortBy, filterBy) {
    const payload = {
      paging: {
        pageNumber,
        pageSize,
      },
      filterBy: Object.assign(
        {},
        ...filterBy.map((x) => ({ [x.id]: x.value })),
      ),
      sortBy: Object.assign({}, ...sortBy.map((x) => ({ [x.id]: x.value }))),
    };
    return apiBase.create().post(`/warehouse/category/list`, payload);
  }

  static getPartList(pageNumber, pageSize, sortBy, filterBy) {
    const payload = {
      paging: {
        pageNumber,
        pageSize,
      },
      filterBy: Object.assign(
        {},
        ...filterBy.map((x) => ({ [x.id]: x.value })),
      ),
      sortBy: Object.assign({}, ...sortBy.map((x) => ({ [x.id]: x.value }))),
    };
    return apiBase.create().post(`/warehouse/part/list`, payload);
  }
  static getCategoryListAll() {
    return apiBase.create().get(`/warehouse/category/list`);
  }

}

export default BikePartsApi;
