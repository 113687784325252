import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import compose from 'recompose/compose';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import MyProfileMenu from './appBar/MyProfileMenu';
import './AppStyles.scss';
import { Button } from '@material-ui/core';
import routePaths from '../routePaths';
import { withMsal } from '@azure/msal-react';

const styles = theme => ({
  appBar: {
    transition: theme.transitions.create('width'),
  },
  [theme.breakpoints.up('lg')]: {
    appBarShift: {
      width: '100%',
    },
    appContainer: {
      marginLeft: 0
    },
    navIconHide: {
      display: 'none',
    },
  },
  title: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden !important',
  },
  [theme.breakpoints.down('sm')]: {
    userName: {
      display: 'none',
    },
  },
});

class AppFrame extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
    };
    new MutationObserver(mutations => this.setState({ title: mutations.length > 0 && mutations[0].target ? mutations[0].target.innerText : '' }))
      .observe(document.querySelector('title'), { subtree: true, characterData: true, childList: true });
  }

  render() {
    const { classes, msalContext } = this.props;
    const appBarClassName = `${classes.appBar} ${classes.appBarShift}`;
    const userName = msalContext.accounts.length > 0
      ? msalContext.accounts[0].name
      : '';
    return (
      <div className="root">
        <AppBar className={appBarClassName} position="fixed" elevation={0}>
          <Toolbar>
            <Button color="inherit" component={Link} to={routePaths.home}>Rowery</Button>
            <Button color="inherit" component={Link} to={routePaths.bikeTypes}>Typy rowerów</Button>
            <Button color="inherit" component={Link} to={routePaths.bikeParts}>Katalog części</Button>
            <Button color="inherit" component={Link} to={routePaths.mobileUserAccounts}>Użytkownicy</Button>
            <Button color="inherit" component={Link} to={routePaths.virtualLocks}>Wirtualne zamki</Button>
            <Button color="inherit" component={Link} to={routePaths.tenants}>Operatorzy</Button>
            <Button color="inherit" component={Link} to={routePaths.withdrawalReport}>Formularze zwrotu środków</Button>
            <Button color="inherit" component={Link} to={routePaths.warehouse}>Części</Button>
            <div className="grow" />
            <Typography color="inherit" className={classes.userName}>{userName}</Typography>
            <MyProfileMenu onSignOut={() => msalContext.instance.logoutRedirect()} />
          </Toolbar>
        </AppBar>
        <div className={classes.appContainer}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

AppFrame.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  width: PropTypes.string,
  msalContext: PropTypes.object,
};

export default compose(
  withStyles(styles, {
    name: 'AppFrame'
  }),
  withWidth(),
)(withMsal(AppFrame));
