import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/bikePartsActions';
import _ from 'lodash';
import {TableModel} from "../models/TableModel";

export default function bikePartsReducer(state = Immutable(initialState.bikeParts), action) {
  switch (action.type) {
    case types.GET_BIKE_PARTS: {
      return state.merge({ fetching: true, data: [] });
    }
    case types.GET_BIKE_PARTS_SUCCESS: {
      return state.merge({
        fetching: false,
        data: action.result,
      });
    }
    case types.CREATE_BIKE_PART_SUCCESS: {
      let newData = [...state.data, { id: action.id, name: action.name }];
      newData = _.sortBy(newData, x => x.name);

      return state.merge({
        fetching: false,
        data: newData,
      });
    }
    case types.GET_BIKE_PARTS_FAILURE: {
      return state.merge({
        fetching: false,
        data: action.result,
      });
    }
    case types.DELETE_BIKE_PART_SUCCESS: {
      return state.merge({
        data: state.data.filter(i => i.id !== action.id),
      });
    }
    case types.GET_CATEGORY_LIST: {
      return Immutable.merge(state, {
        partCategories: {
          pagedData:
            TableModel.pageRequested(state.partCategories.pagedData ? state.partCategories.pagedData : state.partCategories, action.pageNumber, action.pageSize, action.filterBy, action.sortBy),
        }
      });
    }
    case types.GET_CATEGORY_LIST_SUCCESS: {
      return Immutable.merge(state, {
        partCategories: {pagedData: TableModel.pageReady(state.partCategories.pagedData ? state.partCategories.pagedData : state.partCategories, action.result.totalDataCount, action.result.data)}
      });
    }
    case types.GET_BIKE_PART_LIST: {
      return Immutable.merge(state, {
        parts: {
          pagedData:
            TableModel.pageRequested(state.parts.pagedData ? state.parts.pagedData : state.parts, action.pageNumber, action.pageSize, action.filterBy, action.sortBy),
        }
      });
    }
    case types.GET_BIKE_PART_LIST_SUCCESS: {
      return Immutable.merge(state, {
        parts: {pagedData: TableModel.pageReady(state.parts.pagedData ? state.parts.pagedData : state.parts, action.result.totalDataCount, action.result.data)}
      });
    }
    case types.GET_CATEGORY_LIST_ALL: {
      return state.merge({
        fetchingPartCategoriesAll: false,
        partCategoriesAll: action.result,
      });
    }
    case types.GET_CATEGORY_LIST_ALL_SUCCESS: {
      return state.merge({
        fetchingPartCategoriesAll: false,
        partCategoriesAll: action.result,
      });
    }
    default:
      return state;
  }
}
