import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import TextFilter from "../../common/table/filters/TextFilter";

const FiltersPanel = ({onFilteredChange, filteredBy}) => {


  const getFilterValue = useCallback((id) => {
    return filteredBy.find(f => f.id === id);
  },[filteredBy])

  const handleDelete = deleteFunction => onFilteredChange(deleteFunction(filteredBy));
  return (
      <div >
        <div className="flex-row flex-wrap" style={{marginBottom: 16}}>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <TextFilter filter={getFilterValue("name")} onDelete={handleDelete} readonly label="Nazwa kategorii" />
          </div>
        </div>
      </div>
  );

}

FiltersPanel.propTypes = {
  onFilteredChange: PropTypes.func,
  filteredBy: PropTypes.array
};

export default FiltersPanel;
