import React from "react";
import TextFilter from "../../common/table/filters/TextFilter";

/* eslint react/display-name: 0 */
/* eslint react/prop-types: 0 */
/* eslint react/no-multi-comp: 0 */
export const columns = [
  {
    Header: "Nazwa",
    id: "name",
    accessor: (b) => b.name,
    width: 130,
    Filter: ({ filter, onChange }) => (
      <TextFilter onChange={onChange} filter={filter} label="filtruj..." />
    ),  },
];
