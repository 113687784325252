import React, {useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import PropTypes from "prop-types";

const ListItemMenu = () => {

  const [anchorEl, setAnchorEl] = useState(null);

  /*const onClose = () => {
    setAnchorEl(null)
  }*/

  return (
    <React.Fragment>
      <IconButton
        aria-label="More"
        aria-owns={open ? 'list-item-menu' : null}
        aria-haspopup="true"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        style={{width: 30, height: 30}}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="list-item-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null)
        }}
      >
      </Menu>
    </React.Fragment>
  )
}

ListItemMenu.propTypes = {
  itemId: PropTypes.string.isRequired
};

export default ListItemMenu;
